import styled from "styled-components";

const ContainerStyle = styled.section`
  width: 100%;
  margin: 0 auto;

  &:not(.full) {
    width: 100%;
    padding-left: max(48px, calc((100vw - 1360px) / 2));
    padding-right: max(48px, calc((100vw - 1360px) / 2));

    @media (max-width: 800px) {
      padding-left: 16px;
      padding-right: 16px;
    }
  }

  &.section-distance {
    margin-bottom: var(--sectionDistance);
  }

  &.full {
    padding-left: 0;
    padding-right: 0;
  }
`;
export default ContainerStyle;
