'use client';

//* Style
import {
  Custom404Style,
  Title,
  Description,
} from '@/styles/pages/Custom404Style';

//* Helper's
import { config } from '@/helpers';

//* Components
import Page from '@/components/global/Page';
import Button from '@/components/global/Button';
import Image from '@/components/global/Image';

export default function NotFound() {
  return (
    <Page className={`not-found`}>
      <Custom404Style>
        <Image src={'/images/legend/bird3.webp'} width={240} height={240} />
        <Title level="h2" text={'404title'} />
        <Description className={'description'} text={'404description'} />
        <Button
          url={config.routes.home.path}
          className={'primary to-home'}
          text={'goToHomepage'}
        />
      </Custom404Style>
    </Page>
  );
}
