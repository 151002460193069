import {
  forwardRef,
  useRef,
  useImperativeHandle,
  MouseEventHandler,
} from 'react';
import Image from 'next/image';

//* HOC's
import { withUIContext } from '@/context';

//* Style
import ImageStyle from './style';

type CustomImageProps = {
  id?: string;
  src?: string;
  alt?: string;
  className?: string;
  width?: number;
  height?: number;
  priority?: boolean;
  onClick?: MouseEventHandler;
  onMouseEnter?: MouseEventHandler;
  onMouseLeave?: MouseEventHandler;
};

const CustomImage = forwardRef<HTMLElement, CustomImageProps>(
  (
    {
      src,
      alt,
      className,
      width,
      height,
      priority = false,
      onClick,
      id,
      onMouseEnter,
      onMouseLeave,
    },
    ref
  ) => {
    //! Ref
    const imgRef = useRef<HTMLDivElement>(null);

    //! Imperative Handle
    useImperativeHandle(ref, () => imgRef.current as HTMLElement, []);

    return (
      <ImageStyle
        id={id}
        ref={imgRef}
        style={{ width, height }}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        className={`image-cont ${className || ''}`}
      >
        <Image
          fill
          onClick={onClick}
          priority={priority}
          alt={alt || 'Image'}
          sizes="(min-width: 320px) 100%"
          src={src || '/images/default.webp'}
          loading={priority ? 'eager' : 'lazy'}
          unoptimized={src?.includes('.svg')}
        />
      </ImageStyle>
    );
  }
);

CustomImage.displayName = 'CustomImage';

export default withUIContext(CustomImage, ['winWidth']);
