'use client';

import { useEffect } from 'react';

//* Style
import PageStyle from './style';

const Page = (props) => {
  useEffect(() => {
    window.history.scrollRestoration = 'manual';

    return () => {
      window.history.scrollRestoration = 'auto';
    };
  }, []);

  return (
    <PageStyle {...props} className={props.className || ''}>
      {props.children}
    </PageStyle>
  );
};

Page.displayName = 'Page';

export default Page;
