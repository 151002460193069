import styled from 'styled-components';

const PageStyle = styled.main`
  &.home {
    padding-top: 0;
  }

  &.toolkit {
    background-color: var(--white1000);
  }

  &.legal {
    background-color: var(--white1000);
  }
`;
export default PageStyle;
