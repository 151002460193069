'use client';

import { useRef, useImperativeHandle, forwardRef, MouseEventHandler, PropsWithChildren } from 'react';

//* Style
import ContainerStyle from './style';

type ContainerProps = PropsWithChildren & {
	row?: boolean;
	isSection?: boolean;
	full?: boolean;
	className?: string;
	onMouseMove?: MouseEventHandler;
};

const Container = forwardRef<HTMLDivElement, ContainerProps>(({ row, isSection, full, className, children, onMouseMove }, ref) => {
	//! Ref
	const contRef = useRef<HTMLDivElement>(null);

	//! Imperative Handle
	useImperativeHandle(ref, () => contRef.current!, []);

	return (
		<ContainerStyle
			ref={contRef}
			onMouseMove={onMouseMove}
			className={`container ${className || ''} ${isSection ? 'section-distance' : ''} ${full ? 'full' : ''}`}>
			{row ? <div className='row'>{children}</div> : children}
		</ContainerStyle>
	);
});

Container.displayName = 'Container';

export default Container;
