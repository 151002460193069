'use client';

import {
  useRef,
  useImperativeHandle,
  forwardRef,
  PropsWithChildren,
  MouseEventHandler,
} from 'react';

type IconProps = PropsWithChildren & {
  name: string;
  className?: string;
  onClick?: MouseEventHandler;
};

const Icon = forwardRef<HTMLElement, IconProps>(
  ({ name, className, ...props }, ref) => {
    //! Ref
    const iconRef = useRef<HTMLElement>(null);

    //! Imperative Handle
    useImperativeHandle(ref, () => iconRef.current!, []);

    return (
      <i
        ref={iconRef}
        onClick={props.onClick}
        className={`oo-${name} icon ${className || ''}`}
      >
        {props.children}
      </i>
    );
  }
);

Icon.displayName = 'Icon';

export default Icon;
