import styled from 'styled-components';

const ImageStyle = styled.div`
  position: relative;
  width: 100%;

  img {
    object-fit: cover;
    object-position: center;
  }

  &.contain {
    img {
      object-fit: contain;
    }
  }
`;

export default ImageStyle;
