import styled from 'styled-components';
import Container from '@/components/global/Container';
import { HX } from '@/components/global/HX';
import Text from '@/components/global/Text';

export const Custom404Style = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - var(--headerHeight));
  gap: 24px;
  background: #fff;
`;

export const Title = styled(HX)`
  text-align: center;
`;

export const Description = styled(Text)`
  font-family: var(--urbanist);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  max-width: 800px;
  margin-bottom: 24px;
`;
